@import url("./DetailView.css");

.flex-container {
    display: flex;
    flex-wrap: wrap;
    /* background-color: beige; */
    overflow: auto;
    height: calc(100vh - var(--header-height));
    align-content: flex-start;
}

.loading-container {
    height: 100vh;
    align-items: center;
}

.details-container{
    /* flex: 1 1 auto; */
    display: flex;
    flex-direction: column;
    /* background-color: blueviolet; */
    height: 100vh;
    width: 100%;
}
