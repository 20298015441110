.App {
    /* background-color: aliceblue; */
    height: 100vh;
}

.App .body {
    display: flex;
    flex-direction: row;
    /* background-color: lightpink; */
}

.App .content {
    flex: 1 1 auto;
    /* background-color: lightgreen; */
    display: flex;
    flex-direction: row;
}


.body .sidebar {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 12em;
    /* background-color: green; */
}

.App:after,
.App .body:after,
.App .body .content:after,
.App .body .sidebar:after 
{
     content: ''; 
} 